export const DATE_RANGE_LABELS: any = {
  today: 'Today',
  yesterday: 'Yesterday',
  last_7_days: 'Last 7 Days',
  last_14_days: 'Last 14 Days',
  last_30_days: 'Last 30 Days',
  last_90_days: 'Last 90 Days',
  this_month: 'This month',
  last_month: 'Last Month',
  custom: 'Custom',
};
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FNS_FORMAT = 'yyyy-MM-dd';
